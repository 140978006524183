import React from "react";
import { useField } from "formik";
import { Form, Icon, Input, Label } from "semantic-ui-react";
import DatePicker from "react-datepicker";

interface Props {
  label?: string;
  name?: string;
  required?: boolean;
  placeholderText?: string;
  value?: any;
  dateFormat?: any;
}

export default function CustomDateInput(props: Props) {
  const [field, meta, helpers] = useField(props.name!);

  const handleChange = (date: Date | [Date, Date] | null) => {
    helpers.setValue(date);
  };

  return (
    <Form.Field error={meta.touched && !!meta.error}>
      <label>{props.label}</label>

      <div style={{ display: "flex", alignItems: "center" }}>
        <DatePicker
          selected={field.value ? new Date(field.value) : null}
          onChange={handleChange}
          dateFormat={props.dateFormat || "dd.MM.yyyy"}
          showYearDropdown
          scrollableYearDropdown
          yearDropdownItemNumber={15}
          placeholderText={props?.placeholderText}
          isClearable
          customInput={
            <Input
              type="text"
              className={`form-control ${meta.touched && meta.error && "is-invalid"
                }`}
            />
          }
        />
        <Icon
          name="calendar alternate outline"
          color="blue"
          style={{ marginLeft: "5px", cursor: "pointer" }}
        />
        {props.required && (
          <Icon
            name="asterisk"
            color="red"
            size="mini"
            style={{ marginLeft: "5px" }}
          />
        )}
      </div>

      {meta.touched && meta.error && (
        <Label basic color="red">
          {meta.error}
        </Label>
      )}
    </Form.Field>
  );
}
