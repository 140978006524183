import { useTranslation } from "react-i18next";

export enum PaymentType {
    MemberShip = 1,
    Zekat = 2,
    Other = 3,
}

export const PaymentTypeStrings: {
    [key in PaymentType]: string;
} = {
    [PaymentType.MemberShip]: 'membership',
    [PaymentType.Zekat]: 'zekat',
    [PaymentType.Other]: 'other',
};

export const usePaymentTypeOptions = () => {
    const { t } = useTranslation();

    return Object.keys(PaymentType)
        .filter((key: any) => !isNaN(Number(PaymentType[key])))
        .map((key: any) => ({
            key: PaymentType[key],
            value: PaymentType[key],
            text: t(`general.${(PaymentTypeStrings as any)[PaymentType[key]]}`),
        }));
};

export enum PaymentMethod {
    /**Konto */
    Account = 1,
    /**Uplata */
    Payment = 2,
    /**Blok */
    Block = 3,
    /**SEPA */
    Sepa = 4
}

export const PaymentMethodStrings: {
    [key in PaymentMethod]: string;
} = {
    [PaymentMethod.Payment]: 'payment',
    [PaymentMethod.Account]: 'account',
    [PaymentMethod.Block]: 'block',
    [PaymentMethod.Sepa]: 'sepa',
};

export const usePaymentMethodOptions = () => {
    const { t } = useTranslation();

    return Object.keys(PaymentMethod)
        .filter((key: any) => !isNaN(Number(PaymentMethod[key])))
        .map((key: any) => ({
            key: key,
            value: PaymentMethod[key],
            text: t(`general.${(PaymentMethodStrings as any)[PaymentMethod[key]]}`),
        }));
};
